import { gql } from '@apollo/client'

export const UPDATE_SETTINGS = gql`
  mutation updateStoreSettings($storeId: uuid!, $settings: jsonb!) {
    update_stores_by_pk(
      pk_columns: { id: $storeId }
      _append: { settings: $settings }
    ) {
      settings
    }
  }
`

export const UPDATE_POV3_NATIONWIDE_SETTINGS = gql`
  mutation updatePreorderDeliveryArea(
    $storeId: uuid!
    $deliveryAreaSetting: jsonb!
  ) {
    update_pre_order_settings(
      where: { store_id: { _eq: $storeId } }
      _set: { delivery_area_setting: $deliveryAreaSetting }
    ) {
      affected_rows
      returning {
        delivery_area_setting
      }
    }
  }
`

export const UPDATE_STORE_SETTINGS_NATIONWIDE = gql`
  mutation UpdateStoreSettings(
    $storeId: uuid!
    $isDeliveringNationwide: Boolean
    $customDeliveryArea: Boolean
    $deliveryArea: [DeliveryAreaInputObject]
    $busyDeliveryArea: [DeliveryAreaInputObject]
    $preOrderSettings: PreOrderSettingsObject
  ) {
    updateStoreSettings(
      storeId: $storeId
      isDeliveringNationwide: $isDeliveringNationwide
      customDeliveryArea: $customDeliveryArea
      deliveryArea: $deliveryArea
      busyDeliveryArea: $busyDeliveryArea
      preOrderSettings: $preOrderSettings
    ) {
      id
    }
  }
`

export const UPDATE_STORE_BILLABLE = gql`
  mutation updateStoreBillable($storeId: uuid!, $billable: Boolean!) {
    update_stores(
      where: { id: { _eq: $storeId } }
      _set: { billable: $billable }
    ) {
      affected_rows
    }
  }
`

export const UPDATE_STORE_SETTINGS_DEFAULT = gql`
  mutation UpdateStoreSettings(
    $storeId: uuid!
    $isDeliveringNationwide: Boolean
    $customDeliveryArea: Boolean
    $customBusyDeliveryArea: Boolean
    $deliveryRadius: Float
    $busyDeliveryRadius: Float
    $preOrderSettings: PreOrderSettingsObject
  ) {
    updateStoreSettings(
      storeId: $storeId
      isDeliveringNationwide: $isDeliveringNationwide
      customDeliveryArea: $customDeliveryArea
      customBusyDeliveryArea: $customBusyDeliveryArea
      deliveryRadius: $deliveryRadius
      busyDeliveryRadius: $busyDeliveryRadius
      preOrderSettings: $preOrderSettings
    ) {
      id
    }
  }
`

export const FETCH_INTEGRATION_SETTINGS = gql`
  query fetchIntegrationSettings($storeId: uuid!) {
    store_integration_settings(
      where: { store_id: { _eq: $storeId } }
      limit: 1
    ) {
      id
      customer_anonymisation_delivery
    }
  }
`

export const INSERT_INTEGRATION_SETTINGS = gql`
  mutation insertIntegrationSettings(
    $input: store_integration_settings_insert_input!
  ) {
    insert_store_integration_settings_one(object: $input) {
      id
      customer_anonymisation_delivery
      store_id
    }
  }
`

export const UPDATE_INTEGRATION_SETTINGS = gql`
  mutation updateIntegrationSettings(
    $id: uuid!
    $customerAnonymisationDelivery: Boolean
  ) {
    update_store_integration_settings_by_pk(
      pk_columns: { id: $id }
      _set: { customer_anonymisation_delivery: $customerAnonymisationDelivery }
    ) {
      id
      customer_anonymisation_delivery
      store_id
    }
  }
`
