import { useMutation } from '@apollo/react-hooks'
import {
  Table,
  Tag,
  Select,
  notification,
  Typography,
  Switch,
  Tooltip,
  Divider,
  Row,
  Col
} from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import {
  UPDATE_STORE_SETTINGS_NATIONWIDE,
  UPDATE_STORE_SETTINGS_DEFAULT,
  UPDATE_STORE_BILLABLE,
  UPDATE_SETTINGS
} from './StoreQueries'
import ComtrexForm from './Settings/ComtrexForm'
import DeliverectLinkForm from './Settings/DeliverectLinkForm'
import IntegrationForm from './Settings/IntegrationForm'

const { Column } = Table
const { Option } = Select

const UK_MAP_BOUNDARIES = [
  { lat: 54.770315229170315, lng: -0.5271775531249978 },
  { lat: 53.681429504378464, lng: 0.8211871912100754 },
  { lat: 52.81787706450312, lng: 1.7882339786326718 },
  { lat: 51.398524492272024, lng: 1.667634281680268 },
  { lat: 50.912742519948296, lng: 1.1082050134757004 },
  { lat: 50.74062198159814, lng: 0.4835646343749822 },
  { lat: 50.68185239015976, lng: -0.25505889902259105 },
  { lat: 50.711426583283775, lng: -0.9864613210541817 },
  { lat: 50.78962397473712, lng: -1.2948901102732924 },
  { lat: 50.68880638469233, lng: -1.6041305012114937 },
  { lat: 50.164626781994336, lng: -2.7394052079277698 },
  { lat: 49.94140423026941, lng: -5.783554426362816 },
  { lat: 51.36713407006058, lng: -5.807399738232908 },
  { lat: 54.39811419511675, lng: -4.141223174473052 },
  { lat: 54.987627369442485, lng: -5.3132645782033405 },
  { lat: 55.87701307735609, lng: -4.691038791913917 },
  { lat: 56.39544544902894, lng: -3.651332536874494 },
  { lat: 56.540151648645406, lng: -2.5382176906047826 },
  { lat: 55.805305465224514, lng: -1.952446594335091 }
]

const LocationsTable = ({ merchant, client }) => {
  const { stores } = merchant

  const [updateSettings] = useMutation(UPDATE_SETTINGS, {
    fetchPolicy: 'no-cache',
    client: client
  })

  const [updateStoreSettingsToNationwide] = useMutation(
    UPDATE_STORE_SETTINGS_NATIONWIDE,
    {
      fetchPolicy: 'no-cache',
      client: client
    }
  )

  const [updateStoreBillable] = useMutation(UPDATE_STORE_BILLABLE, {
    fetchPolicy: 'no-cache',
    client: client,
    refetchQueries: ['queryMerchant']
  })

  const [updateStoreSettingsToDefault] = useMutation(
    UPDATE_STORE_SETTINGS_DEFAULT,
    {
      fetchPolicy: 'no-cache',
      client: client
    }
  )

  const handleNationWideChange = (storeId, is_nationwide) => {
    if (!!is_nationwide) {
      updateStoreSettingsToNationwide({
        variables: {
          storeId: storeId,
          isDeliveringNationwide: true,
          customDeliveryArea: true,
          customBusyDeliveryArea: true,
          deliveryArea: UK_MAP_BOUNDARIES,
          busyDeliveryArea: UK_MAP_BOUNDARIES,
          preOrderSettings: {
            custom_delivery_area: true,
            delivery_area: UK_MAP_BOUNDARIES
          }
        }
      })
        .then(() => {
          const messagePart = 'activated'

          notification['success']({
            message: `Location has ${messagePart} nationwide mode`
          })
        })
        .catch(() => {
          notification['error']({ message: 'Please try again later' })
        })
    } else {
      updateStoreSettingsToDefault({
        variables: {
          storeId: storeId,
          isDeliveringNationwide: false,
          customDeliveryArea: false,
          customBusyDeliveryArea: false,
          deliveryRadius: 4,
          busyDeliveryRadius: 4,
          preOrderSettings: {
            delivery_radius: 4,
            custom_delivery_area: false
          }
        }
      })
        .then(() => {
          const messagePart = 'disabled'

          notification['success']({
            message: `Location has ${messagePart} nationwide mode`
          })
        })
        .catch(() => {
          notification['error']({ message: 'Please try again later' })
        })
    }
  }

  const handleBillableChange = (storeId: string, billable: boolean) => {
    updateStoreBillable({
      variables: { storeId, billable }
    })
      .then(() => {
        notification['success']({
          message: `Location billable has been ${
            billable ? 'enabled' : 'disbaled'
          }`
        })
      })
      .catch(() => {
        notification['error']({ message: 'Please try again later' })
      })
  }

  const handleTypeChange = (storeId, type = 'strict') => {
    updateSettings({
      variables: { storeId: storeId, settings: { delivery_validation: type } }
    })
      .then(() => {
        notification['success']({
          message: `Location has been set to validate addresses in ${type} mode`
        })
      })
      .catch(() => {
        notification['error']({ message: 'Please try again later' })
      })
  }

  return (
    <>
      <Table dataSource={stores} pagination={false}>
        <Column
          dataIndex='name'
          key='name'
          width='40%'
          render={(text: string, record: any) => {
            return (
              <>
                <Typography.Title level={2} style={{ lineHeight: 0 }}>
                  {text}
                </Typography.Title>{' '}
                {record.address && (
                  <>
                    <Typography.Text type='secondary'>
                      {record.email} ({record.address.contact_num})
                    </Typography.Text>
                    <Tag>
                      <Typography.Text type='secondary'>
                        {record.id}
                      </Typography.Text>
                    </Tag>
                  </>
                )}
                {record.is_open ? (
                  <Tag color='green'>Open</Tag>
                ) : (
                  <Tag color='red'>Closed</Tag>
                )}
                <Divider />
                <Row justify='space-between'>
                  <Col>
                    Delivery validation mode
                    <Tooltip title="Sets the address validation mode in the checkout form. Strict mode only allows for addresses that can be pinpointed via google maps. Flexible allows for a more loose validation that accepts addresses that can't be pinpoiinted via google maps">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Col>
                  <Col>
                    <Select
                      defaultValue={
                        record.settings.delivery_validation || 'strict'
                      }
                      onChange={(type) => handleTypeChange(record.id, type)}
                    >
                      <Option value='strict'> Strict </Option>
                      <Option value='flexible'> Flexible </Option>
                    </Select>
                  </Col>
                </Row>
                <Divider />
                <Row justify='space-between'>
                  <Col>National Delivery</Col>
                  <Col>
                    <Switch
                      defaultChecked={record.settings.is_delivering_nationwide}
                      onChange={(value) =>
                        handleNationWideChange(record.id, value)
                      }
                    />
                  </Col>
                </Row>
                <Divider />
                <Row justify='space-between'>
                  <Col>Billable location</Col>
                  <Col>
                    <Switch
                      defaultChecked={record.billable}
                      disabled={!!record.archived_at}
                      onChange={(value) =>
                        handleBillableChange(record.id, value)
                      }
                    />
                  </Col>
                </Row>
              </>
            )
          }}
        />
        <Column width='2%'></Column>
        <Column
          width='48%'
          key='comtrex_api_keys'
          render={(text: string, record: any) => {
            return (
              <>
                <Typography.Title level={4}>Comtrex Keys</Typography.Title>
                <ComtrexForm store={record} client={client} />
                <Divider />
                <Typography.Title level={4}>Deliverect Keys</Typography.Title>
                <DeliverectLinkForm store={record} client={client} />
                <Divider />
                <Typography.Title level={4}>
                  Integration Settings
                </Typography.Title>
                <IntegrationForm store={record} client={client} />
              </>
            )
          }}
        />
      </Table>
    </>
  )
}

export default LocationsTable
