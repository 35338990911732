import { gql } from '@apollo/client'

export const QUERY_MERCHANTS = gql`
  query merchantStoreStats(
    $sector: String
    $icp: String
    $published: Boolean = true
    $limit: Int! = 10
    $isNull: Boolean = false
  ) {
    merchants(
      where: {
        # _or: [
        #   { sector: { _eq: $sector, _is_null: $isNull } }
        #   { icp: { _eq: $icp, _is_null: $isNull } }
        # ]
        sector: { _eq: $sector }
        icp: { _eq: $icp }
        _not: { published_at: { _is_null: $published } }
      }
      limit: $limit
    ) {
      id
      name
      slug
      sector
      icp
      published_at
      # weekly_sales {
      #   count
      #   total
      #   day
      # }
      stats {
        open
        total_orders
        pending_orders
        closed
        current_plan
        last_ordered_at
      }
      stores(where: { archived_at: { _is_null: true } }) {
        id
        name
        slug
        address_cache
        is_open
      }
      users {
        id
        role
        email
        store {
          slug
        }
      }
    }
  }
`

export const QUERY_MERCHANT_STORES = gql`
  query merchantStoreStats(
    $sector: String
    $published: Boolean = true
    $limit: Int! = 10
  ) {
    merchants(
      where: {
        sector: { _eq: $sector }
        _not: { published_at: { _is_null: $published } }
      }
      limit: $limit
    ) {
      id
      name
      slug
      sector
      published_at
      stats {
        last_ordered_at
      }
      stores(where: { archived_at: { _is_null: true } }) {
        id
        name
        slug
        address_cache
        is_open
      }
    }
  }
`

export const QUERY_ALL_PLANS = gql`
  query queryAllPlans {
    allPlans {
      code
      name
      planId
      priority
      commission {
        delivery
        pickup
        preorderDelivery
        preorderPickup
        table
      }
    }
  }
`

export const QUERY_FREE_ORDER_COMMISSION_PLANS = gql`
  query queryFreeOrderCommissionPlans {
    zero_order_rates {
      delivery
      id
      name
      nationwide_delivery
      pickup
      preorder_delivery
      preorder_pickup
      preorder_self_delivery
      same_day_self_delivery
      table
    }
  }
`

const QUERY_MERCHANT = gql`
  query queryMerchant($slug: String!) {
    merchants(where: { slug: { _eq: $slug } }) {
      id
      name
      slug
      published_at
      sector
      stripe_account_type
      transaction_plan_id
      icp
      setting
      transaction_plan_id
      is_seo_enabled
      app_setting {
        google_play_url
        apple_store_url
        enabled
      }
      orders_aggregate {
        aggregate {
          count(columns: id)
        }
      }
      pickup_orders: orders_aggregate(
        where: { fulfillment_type: { _eq: "pickup" } }
      ) {
        aggregate {
          count(columns: id)
        }
      }
      delivery_orders: orders_aggregate(
        where: { fulfillment_type: { _eq: "delivery" } }
      ) {
        aggregate {
          count(columns: id)
        }
      }
      stores {
        name
        slug
        address {
          id
          geom
          line_1
          line_2
          city
          zip
          contact_num
        }
        email
        is_open
        inserted_at
        id
        settings
        billable
        archived_at
      }
      merchant_users {
        role
        user {
          id
          email
          firstname
          lastname
          role
          store {
            name
          }
        }
      }
      users(order_by: { role: asc }) {
        id
        email
        role
        store {
          slug
        }
      }
      price_matrices(where: { archived_at: { _is_null: true } }) {
        id
        name
        data
        global
        stores {
          id
        }
      }
      current_plan
      zero_order_rate_id
      account_info
      internal_notes
      for_testing
      integration_settings
    }
  }
`

const SUBSCRIPTION_MERCHANT_LIST = gql`
  query merchantsList {
    merchants(order_by: { published_at: asc_nulls_last }) {
      id
      orders_aggregate {
        aggregate {
          count
        }
      }
      name
      slug
      published_at
      stores_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

const QUERY_MERCHANT_MONTHLY_ORDERS = gql`
  query merchantStoreStats {
    view_monthly_merchant_orders {
      delivery_charge
      merchant_id
      month
      name
      total
      year
    }
  }
`

const QUERY_MERCHANT_ORDERS_BY_MONTH = gql`
  query merchantStoreStatsByMerchant($merchantId: uuid!) {
    view_monthly_merchant_orders(where: { merchant_id: { _eq: $merchantId } }) {
      delivery_charge
      merchant_id
      month
      name
      total
      year
    }
  }
`

const UPDATE_MERCHANT_SECTOR = gql`
  mutation updateMerchantSector($merchantId: uuid!, $sector: String!) {
    update_merchants(
      where: { id: { _eq: $merchantId } }
      _set: { sector: $sector }
    ) {
      affected_rows
      returning {
        sector
      }
    }
  }
`

const UPDATE_MERCHANT_ICP = gql`
  mutation updateMerchantICP($merchantId: uuid!, $icp: String!) {
    update_merchants(where: { id: { _eq: $merchantId } }, _set: { icp: $icp }) {
      affected_rows
      returning {
        sector
      }
    }
  }
`

const UPDATE_MERCHANT_SUBSCRIPTION_PLAN = gql`
  mutation updateMerchantSubscriptionPlan($merchantId: String, $plan: String!) {
    updatePlan(merchantId: $merchantId, plan: $plan) {
      currentPlan
    }
  }
`

const UPDATE_MERCHANT_ZERO_ORDER_PLAN = gql`
  mutation updateZeroOrderPlan($merchantId: uuid!, $zeroOrderRateId: ID!) {
    updateZeroOrderPlan(merchantId: $merchantId, planId: $zeroOrderRateId) {
      id
    }
  }
`

const UPDATE_PRICE_MATRIX = gql`
  mutation updatePriceMatrix($merchantId: uuid!, $data: jsonb!) {
    update_price_matrices(
      where: { merchant_id: { _eq: $merchantId } }
      _set: { data: $data }
    ) {
      affected_rows
      returning {
        data
      }
    }
  }
`

const UPDATE_PRICE_MATRIX_BY_ID = gql`
  mutation updatePriceMatrix(
    $merchantId: uuid!
    $data: jsonb!
    $rateCardId: uuid!
  ) {
    update_price_matrices(
      where: { merchant_id: { _eq: $merchantId }, id: { _eq: $rateCardId } }
      _set: { data: $data }
    ) {
      affected_rows
      returning {
        id
        data
        stores {
          id
        }
      }
    }
  }
`

const CREATE_PRICE_MATRIX = gql`
  mutation createPriceMatrix($object: price_matrices_insert_input!) {
    insert_price_matrices_one(object: $object) {
      id
      data
      stores {
        id
      }
    }
  }
`

const CREATE_AND_ASSIGN_TO_STORES_PRICE_MATRIX = gql`
  mutation createAndAssignToStoresPriceMatrix(
    $object: price_matrices_insert_input!
    $storeIds: [uuid!]
    $priceMatrixId: uuid!
  ) {
    insert_price_matrices_one(object: $object) {
      id
      data
      stores {
        id
      }
    }

    update_stores(
      where: { id: { _in: $storeIds } }
      _set: { price_matrix_id: $priceMatrixId }
    ) {
      affected_rows
      returning {
        price_matrix_id
      }
    }
  }
`

const ARCHIVE_PRICE_MATRIX = gql`
  mutation archivePriceMatrices($id: uuid!, $archived_at: timestamp) {
    update_price_matrices(
      where: { id: { _eq: $id } }
      _set: { archived_at: $archived_at }
    ) {
      affected_rows
      returning {
        archived_at
      }
    }
  }
`

const RENAME_PRICE_MATRIX = gql`
  mutation renamePriceMatrix($priceMatrixId: uuid!, $name: String!) {
    update_price_matrices(
      where: { id: { _eq: $priceMatrixId } }
      _set: { name: $name }
    ) {
      affected_rows
      returning {
        name
      }
    }
  }
`

const ASSIGN_PRICE_MATRIX_TO_STORE = gql`
  mutation assignPriceMatrixToStore($storeIds: [uuid!], $priceMatrixId: uuid!) {
    update_stores(
      where: { id: { _in: $storeIds } }
      _set: { price_matrix_id: $priceMatrixId }
    ) {
      affected_rows
      returning {
        price_matrix_id
      }
    }
  }
`

const REMOVE_PRICE_MATRIX_FROM_STORE = gql`
  mutation removePriceMatrixFromStore($storeIds: [uuid!]) {
    update_stores(
      where: { id: { _in: $storeIds } }
      _set: { price_matrix_id: null }
    ) {
      affected_rows
      returning {
        price_matrix_id
      }
    }
  }
`

const INVALID_MERCHANTS = gql`
  query ProblematicPartners {
    merchants(
      where: {
        sector: { _is_null: true }
        published_at: { _gte: "2023-01-01" }
      }
    ) {
      name
      slug
      published_at
      sector
      orders_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export {
  SUBSCRIPTION_MERCHANT_LIST,
  QUERY_MERCHANT,
  QUERY_MERCHANT_MONTHLY_ORDERS,
  QUERY_MERCHANT_ORDERS_BY_MONTH,
  UPDATE_MERCHANT_SECTOR,
  UPDATE_MERCHANT_ICP,
  UPDATE_MERCHANT_SUBSCRIPTION_PLAN,
  UPDATE_MERCHANT_ZERO_ORDER_PLAN,
  UPDATE_PRICE_MATRIX,
  CREATE_PRICE_MATRIX,
  UPDATE_PRICE_MATRIX_BY_ID,
  ARCHIVE_PRICE_MATRIX,
  ASSIGN_PRICE_MATRIX_TO_STORE,
  REMOVE_PRICE_MATRIX_FROM_STORE,
  RENAME_PRICE_MATRIX,
  CREATE_AND_ASSIGN_TO_STORES_PRICE_MATRIX,
  INVALID_MERCHANTS
}
